<template>
  <disclosure-container>
    <template #form="props">
      <h1 class="form__title">
        {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.TITLE') }}
      </h1>

      <span>
        {{
          $t('DISCLOSURE_FORMS.OTHER_CONFLICT.SUBTITLE', {
            companyName: company.name,
          })
        }}
      </span>

      <form @submit.prevent="submitDisclosure(props)">
        <div>
          <label class="label" for="relationshipName">
            {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.DISCLOSURE_CATEGORY') }}
          </label>
          <input
            id="relatedPerson"
            v-model="disclosure.disclosureCategory"
            type="text"
            class="text-input"
            name="relatedPerson"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
            required
          />
        </div>

        <div>
          <label class="label" for="relationshipName">
            {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.DESCRIPTION') }}
          </label>
          <input
            id="relatedPerson"
            v-model="disclosure.description"
            type="text"
            class="text-input"
            name="relatedPerson"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
            required
          />
        </div>

        <div>
          <label class="label" for="relationshipName">
            {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.RELATIONSHIP_PERSON_NAME') }}
          </label>
          <input
            id="relatedPerson"
            v-model="disclosure.relationshipPersonName"
            type="text"
            class="text-input"
            name="relatedPerson"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
          />
        </div>

        <div>
          <label class="label" for="relationshipName">
            {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.ORG_NAME') }}
          </label>
          <input
            id="relatedPerson"
            v-model="disclosure.orgName"
            type="text"
            class="text-input"
            name="relatedPerson"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
          />
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.OTHER_CONFLICT.IS_MANAGER_AWARE') }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.isManagerAware"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.isManagerAware"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>

        <div>
          <p class="label">
            {{
              $t('DISCLOSURE_FORMS.OTHER_CONFLICT.IS_HR_AWARE', {
                companyName: company.name,
              })
            }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.isHrAware"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.isHrAware"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>

        <div>
          <label class="label" for="additionalComments">
            {{ $t('DISCLOSURE_FORMS.COMMENTS') }}
          </label>
          <textarea
            id="additionalComments"
            v-model="disclosure.observations"
            name="additionalComments"
            rows="5"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
          />
        </div>

        <div>
          <p class="description center">
            {{ $t('DISCLOSURE_FORMS.INFORMATIONS') }}
          </p>
          <s-button
            id="disclosure"
            :disabled="!isValid"
            type="submit"
            btn-style="eco large"
          >
            {{ $t('DISCLOSURE_FORMS.SEND') }}
          </s-button>

          <p class="description-footer center">
            {{ $t('DISCLOSURE_FORMS.FOOTER_SAFESPACE') }}
          </p>
        </div>
      </form>
    </template>
  </disclosure-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Disclosures } from '@/domain/disclosure'
import DisclosureContainer from './DisclosureContainer'
import SButton from '@/components//ui/s-button'

import backIcon from '@/assets/images/issues/back_eco_icon.svg'

export default {
  name: 'OtherPotencialConflitInterest',

  components: {
    SButton,
    DisclosureContainer,
  },

  data() {
    return {
      disclosure: { ...Disclosures.OtherPotencialConflitInterest },
      backIcon,
    }
  },

  computed: {
    ...mapState(['company']),

    isValid() {
      const { disclosureCategory, description, isManagerAware, isHrAware } =
        this.disclosure

      return [disclosureCategory, description, isManagerAware, isHrAware].every(
        (item) => item !== null && item !== '',
      )
    },
  },

  methods: {
    ...mapActions(['createDisclosure']),

    async submitDisclosure(props) {
      const params = {
        ...props,
        ...this.disclosure,
      }

      const result = await this.createDisclosure(params)

      this.disclosure = { ...Disclosures.OtherPotencialConflitInterest }

      const { slug, slugId } = this.company
      this.$router.push({
        name: 'company-disclosure-success',
        params: { slug, slugId, agree: true, ...result },
      })
    },
  },
}
</script>
